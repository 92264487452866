import React from 'react';
import ImageRenderer from '@app/components/media-renderer/image-renderer';
import environments from '@app/configs/environments';
import { ApiResponse, ProductAttributes } from '@app/models/dtos/strapi';
import { Typography } from '@mui/material';
import { useRouter } from 'next/router';

function ProductCard({ product }: { product: ApiResponse<ProductAttributes> }) {
    const router = useRouter();
    return (
        <div onClick={() => router.push(`/product/${product.attributes.name.toLowerCase()}`)} className="flex cursor-pointer flex-col items-center justify-center gap-3 border  border-black-1 p-4">
            {product?.attributes.logo && 'data' in product.attributes.logo && <ImageRenderer media={environments.STRAPI_HOST_URL + product.attributes.logo.data?.attributes.url} className="!h-fit !w-fit filter-none" isGrayScale={false} />}
            <Typography className="microCopy" noWrap>
                {product.attributes.name}
            </Typography>
        </div>
    );
}

export default ProductCard;
