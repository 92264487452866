import React from 'react';
import ArrowTopRight from '@app/components/icons/arrow-top-right';
import { useFullScreenModal } from '@app/components/modal-views/full-screen-modal-context';
import { ApiResponse, LocationAttributes } from '@app/models/dtos/strapi';

export default function LetsTalk({ locations }: { locations?: Array<ApiResponse<LocationAttributes>> }) {
    const { openModal } = useFullScreenModal();
    return (
        <div className="flex w-full flex-col items-center justify-center gap-2 bg-blue-3 py-8 md:py-14">
            <div>
                <p className="P2 mb-2 !text-black-1">Want to work with us?</p>
                <div onClick={() => openModal('CONTACT_US', { locations })} className="flex cursor-pointer items-center gap-3 !text-black-1 underline-offset-[16px] hover:underline md:gap-6">
                    <span className="h4-responsive">Let’s Talk</span>
                    <ArrowTopRight className="h-4 w-4 md:h-[28px] md:w-[28px]" />
                </div>
            </div>
        </div>
    );
}
