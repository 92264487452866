import React from 'react';

import { Box } from '@mui/material';
import cn from 'classnames';
import Navbar from '@app/layouts/navbar';
import Footer from '@app/layouts/footer';
import { AnimatePresence, motion } from 'framer-motion';
import { ILayoutProps } from '@app/models/props/home';

interface LayoutProps extends ILayoutProps {
    className?: string;
    childClassName?: string;
}

export default function Layout({ children, className = '', childClassName = '', settings, locations, products, socialMedias }: React.PropsWithChildren<LayoutProps>) {
    return (
        <AnimatePresence mode="wait" initial={true} onExitComplete={() => window.scrollTo(0, 0)}>
            <div className="flex !min-h-screen !min-w-full flex-col items-center  justify-between bg-white">
                <Navbar settings={settings} locations={locations} products={products} />
                <Box className={`mt-[68px]  w-full bg-white ${className}`} component="main" sx={{ display: 'flex', width: '100%' }}>
                    <motion.div
                        initial={{ x: 0, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 800, opacity: 0 }}
                        transition={{
                            ease: 'linear',
                            duration: 0.8,
                            x: { duration: 0.8 }
                        }}
                        className={cn(`max-w-screen relative h-full w-full overflow-clip ${childClassName}`)}
                    >
                        {children}
                    </motion.div>
                </Box>
                <Footer locations={locations} products={products} settings={settings} socialMedias={socialMedias} />
            </div>
        </AnimatePresence>
    );
}
