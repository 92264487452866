import React from 'react';
import ChevronDown from '@app/components/icons/chevron-down';
import { IconButton, IconButtonPropsSizeOverrides, Menu, PaperProps, PopoverOrigin } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

interface IMenuDropdownProps {
    id: string;
    menuContent: React.ReactNode | React.ReactNode[];
    children: React.ReactNode | React.ReactNode[];
    width?: number;
    className?: string;
    onClick?: any;
    size?: OverridableStringUnion<'small' | 'large' | 'medium', IconButtonPropsSizeOverrides>;
    fullWidth?: boolean;
    showExpandMore?: boolean;
    PaperProps?: Partial<PaperProps>;
    transformOrigin?: PopoverOrigin;
    anchorOrigin?: PopoverOrigin;
    menuClassName?: string;
}

const defaultPaperProps: PaperProps = {
    elevation: 0,
    sx: {
        overflow: 'hidden',
        border: 1,
        borderRadius: 0,
        mt: 0.5,
        padding: 0
    }
};

const defaultTransformOrigin: PopoverOrigin = { horizontal: 'center', vertical: 'top' };
const defaultAnchorOrigin: PopoverOrigin = { horizontal: 'center', vertical: 'bottom' };

export default function MenuDropdown({
    id,
    menuContent,
    children,
    className = '',
    menuClassName,
    width = 289,
    onClick = undefined,
    size = 'small',
    fullWidth = false,
    showExpandMore = true,
    PaperProps = defaultPaperProps,
    transformOrigin = defaultTransformOrigin,
    anchorOrigin = defaultAnchorOrigin
}: IMenuDropdownProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        if (onClick) onClick(event);
    };

    const handleClose = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    if (width) PaperProps.sx = { ...PaperProps.sx, width: width };

    return (
        <>
            <IconButton
                sx={{ padding: 1 }}
                className={`${fullWidth ? 'w-full' : 'w-fit'} P1 flex justify-between gap-2 rounded !text-black-1 hover:bg-transparent  ${className}`}
                onClick={handleClick}
                size={size}
                aria-controls={open ? id : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <span className="flex items-center gap-2">{menuContent}</span>
                {showExpandMore && (
                    <div className={`${open ? '!rotate-180' : '!-rotate-0'} transition-all duration-300`}>
                        <ChevronDown />
                    </div>
                )}
            </IconButton>

            <Menu className={menuClassName} id={id} anchorEl={anchorEl} open={open} onClose={handleClose} draggable disableScrollLock={true} PaperProps={PaperProps} transformOrigin={transformOrigin} anchorOrigin={anchorOrigin}>
                {children}
            </Menu>
        </>
    );
}
