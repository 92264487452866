import type { LinkProps } from 'next/link';
import NextLink from 'next/link';

interface IAnchorLinkProps extends LinkProps {
    href: string;
    props?: any;
    className?: string;
}
const AnchorLink: React.FC<IAnchorLinkProps & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>> = ({ href, className, ...props }: IAnchorLinkProps) => {
    return <NextLink href={href} className={`underline-offset-[8px] hover:md:underline ${className} `} passHref {...props}></NextLink>;
};

export default AnchorLink;
