import React from 'react';

import BrokenFile from '@app/assets/images/broken-file.svg';
import cn from 'classnames';
import Image, { StaticImageData } from 'next/image';

interface IImageRenderer {
    media: StaticImageData | string;
    className?: string;
    isGrayScale?: boolean;
}

export default function ImageRenderer({ media, className, isGrayScale = true }: IImageRenderer) {
    // let src = `${environments.IPFS_DEFAULT_URL}/${media.media}`;
    // if (!media.media) src = BrokenFile;
    let src = media;
    if (!media) src = BrokenFile;
    const Img = <Image alt="sireto" src={src} height={2000} width={2000} loading="lazy" className={cn(' h-full w-full object-cover', className, isGrayScale && 'grayscale-0 saturate-0')} />;
    return isGrayScale ? (
        <div className="relative h-full w-full overflow-hidden">
            {Img}
            <div className="absolute left-0 top-0 h-full w-full bg-blue-opacity"></div>
        </div>
    ) : (
        Img
    );
}
