import React from 'react';

function ChevronDown(props: React.SVGAttributes<{}>) {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M6.5 9L12.5 15L18.5 9" stroke="#242424" />
        </svg>
    );
}

export default ChevronDown;
