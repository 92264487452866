import React from 'react';
import AnchorLink from '@app/components/ui/links/anchor-link';

export default function Logo() {
    return (
        <AnchorLink href="/" className="font-baiJamjuree text-[28px] font-medium leading-none text-blue hover:!no-underline">
            SIRETO
        </AnchorLink>
    );
}
