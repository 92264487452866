import React from 'react';

export default function ArrowTopRight(props: React.SVGAttributes<{}>) {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M2 2H26V26" stroke="currentColor" strokeWidth="3" />
            <path d="M2 26L26 2" stroke="currentColor" strokeWidth="3" />
        </svg>
    );
}
