import Burger from '@app/components/icons/burger';
import Logo from '@app/components/ui/logo';
import React, { useState } from 'react';
import Button from '@app/components/ui/button';
import { useTranslation } from 'next-i18next';
import { locales } from '@app/constants/locales';
import Navigation from '@app/components/ui/navigation';
import { useFullScreenModal } from '@app/components/modal-views/full-screen-modal-context';
import { ILayoutProps } from '@app/models/props/home';

export default function Navbar({ settings, locations, products }: ILayoutProps) {
    const [isMenuVisible, setMenuVisible] = useState(false);
    const { t } = useTranslation();
    const { openModal } = useFullScreenModal();
    return (
        <nav className="fixed top-0 !z-30 flex w-full flex-col items-center justify-center bg-white bg-opacity-70 backdrop-blur-[10px]">
            <div className="container flex w-full flex-col ">
                <div className="flex h-[61px] w-full items-center justify-between ">
                    <Logo />
                    {settings?.navigation && <Navigation navigations={settings.navigation} products={products} className="hidden lg:flex" />}
                    <Button className="hidden lg:inline-block" onClick={() => openModal('CONTACT_US', { locations })}>
                        {t(locales.letsTalk)}
                    </Button>
                    {/* <!-- mobile button goes here --> */}
                    <div className="flex items-center lg:hidden">
                        <Burger
                            className="h-6 w-6 cursor-pointer"
                            onClick={() => {
                                setMenuVisible(!isMenuVisible);
                            }}
                        />
                    </div>
                </div>
                {/* <!-- mobile menu --> */}
            </div>
            {isMenuVisible && (
                <div className="flex w-full flex-col items-center bg-white px-5 py-6 lg:hidden">
                    {settings?.navigation && <Navigation navigations={settings.navigation} products={products} className="flex flex-col" />}
                    <Button className="my-10 flex w-full items-center md:w-fit" onClick={() => openModal('CONTACT_US', { locations })}>
                        {t(locales.letsTalk)}
                    </Button>
                </div>
            )}
        </nav>
    );
}
