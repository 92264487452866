import React from 'react';
import { ApiResponse, NavigationDto, ProductAttributes } from '@app/models/dtos/strapi';
import cn from 'classnames';
import { useRouter } from 'next/router';
import AnchorLink from '@app/components/ui/links/anchor-link';
import MenuDropdown from '@app/components/menu-dropdowm';
import { MenuItem } from '@mui/material';
import ImageRenderer from '@app/components/media-renderer/image-renderer';
import environments from '@app/configs/environments';
import ArrowTopRight from '@app/components/icons/arrow-top-right';
import ProductCard from '@app/components/cards/product-card';
import { getProductRoute } from '@app/utils/productUtils';

interface INavigationProps {
    navigations: Array<NavigationDto>;
    className?: string;
    products?: Array<ApiResponse<ProductAttributes>>;
}

function Navigation({ navigations, className, products }: INavigationProps) {
    const router = useRouter();
    products?.sort((a, b) => a.id - b.id);
    const getProductNavigation = () => {
        if (products && products?.length >= 3) {
            return {
                width: 894,
                cardsPerRow: 3
            };
        } else if (products && products?.length === 2) {
            return {
                width: 596,
                cardsPerRow: 2
            };
        } else {
            return {
                width: 298,
                cardsPerRow: 1
            };
        }
    };

    return (
        <ul className={cn('P1 w-full items-center  justify-center gap-10 !text-black-1 ', className)}>
            {navigations.map((navigation) => {
                if (navigation.label.toLowerCase() !== 'products')
                    return (
                        <li key={navigation.url} className={cn(navigation.url === `${router.pathname}/` ? 'underline-offset-[8px] md:underline ' : '')}>
                            <AnchorLink href={navigation.label.toLowerCase() === 'blogs' ? `${environments.GHOST_HOST_URL}` : navigation.url}>{navigation.label}</AnchorLink>
                        </li>
                    );
                return (
                    <div key={navigation.url}>
                        <MenuDropdown className="hidden md:flex" width={getProductNavigation().width} menuClassName="md:flex hidden p-3 gap-3  w-full" id="navigation-id" menuContent={navigation.label}>
                            {products &&
                                products.slice(0, 3).map((product, index) => (
                                    <MenuItem key={product.id} className="flex p-0 hover:!bg-transparent">
                                        {index === 1 && products.length >= 2 && <div className="mr-3 h-full w-[1px] bg-black-1"></div>}
                                        <div onClick={() => router.push(getProductRoute(product))} className="flex h-full max-w-[274px]  flex-col items-start  p-6 hover:bg-lightBackground">
                                            <div className="flex h-full flex-col items-start">
                                                <div className="!h-8">
                                                    {product?.attributes.logo && 'data' in product.attributes.logo && (
                                                        <ImageRenderer isGrayScale={false} className="!h-fit !max-h-8  max-w-[186px] !object-contain  filter-none" media={environments.STRAPI_HOST_URL + product.attributes.logo.data?.attributes.url} />
                                                    )}
                                                </div>
                                                <p className="P2 mb-[29px] mt-6 flex w-full !max-w-[274px] overflow-hidden whitespace-normal text-black-2">{product.attributes.description}</p>
                                            </div>
                                            <AnchorLink href={getProductRoute(product)} className="flex items-center gap-2 decoration-blue">
                                                <span className="P1 !text-blue">Learn More</span>
                                                <ArrowTopRight className="h-3 w-3 text-blue" />
                                            </AnchorLink>
                                        </div>
                                        {index === 1 && products.length >= 3 && <div className="ml-3 h-full w-[1px] bg-black-1"></div>}
                                    </MenuItem>
                                ))}
                        </MenuDropdown>
                        {products && products.length !== 0 && (
                            <div className="flex w-full flex-col items-center md:hidden">
                                <p className="P1 mb-4 !text-black-1">Products</p>
                                <div
                                    style={{
                                        gridTemplateColumns: `repeat(${getProductNavigation().cardsPerRow}, 1fr)`
                                    }}
                                    className={`grid w-full grid-flow-row gap-2 sm:gap-4`}
                                >
                                    {products.slice(0, 3).map((product) => (
                                        <ProductCard key={product.id} product={product} />
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
        </ul>
    );
}

export default Navigation;
